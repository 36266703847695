import { isBrowser } from "../../hooks/auth";
import { navigate } from "gatsby";

const SoftwarePage = () => {
  if (isBrowser()) {
    navigate("/software/bim");
  }
};

export default SoftwarePage;

export { default as CAD } from "./cad";
export { default as BIM } from "./bim";
